import React from "react"
import PropTypes from "prop-types"

const converDoubanImg = url => {
  if (!url) {
    return null
  }
  return `https://www.notion.so/image/${encodeURIComponent(url)}?width=115`
}
const BookCard = ({ cover, name, link }) => {
  const src = converDoubanImg(cover)
  return (
    <div style={{ display: "inline-block" }}>
      <div
        style={{
          width: "135px",
          height: "172px",
          overflow: "hidden",
          display: "flex",
          marginRight: "10px",
        }}
      >
        <a href={link} target="_blank">
          <img
            alt={name}
            src={src}
            referrerpolicy="never"
            style={{ marginBottom: "0", alignSelf: "flex-end" }}
          />
        </a>
      </div>
      <p
        style={{
          width: "135px",
          fontSize: "14px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {name}
      </p>
    </div>
  )
}

BookCard.propTypes = {
  link: PropTypes.string,
  cover: PropTypes.string,
  name: PropTypes.string,
}
export default BookCard
