import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BookCard from "../components/BookCard"

const getReadingBooks = (books = []) => {
  return books.filter(book => book.status === "正在阅读")
}

const getFinishedBooks = (books = []) => {
  return books.filter(book => ["读完", "已做读书笔记"].includes(book.status))
}

class Books extends React.Component {
  render() {
    const {
      allBooks: { nodes: books },
      site: {
        siteMetadata: { title },
      },
    } = this.props.data
    return (
      <Layout location={this.props.location} title={title}>
        <SEO title="我的书单" />
        <section>
          <h3
            style={{
              color: "rgb(224,62,62)",
            }}
          >
            正在看的书
          </h3>
          {getReadingBooks(books).map(book => (
            <BookCard
              name={book.name}
              cover={book.book_cover}
              link={book.douban_link}
            />
          ))}
        </section>
        <section>
          <h3
            style={{
              color: "rgb(224,62,62)",
            }}
          >
            看过的书
          </h3>
          {getFinishedBooks(books).map(book => (
            <BookCard
              name={book.name}
              cover={book.book_cover}
              link={book.douban_link}
            />
          ))}
        </section>
      </Layout>
    )
  }
}
export default Books

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allBooks(sort: { fields: finish_date___startDate, order: DESC }) {
      nodes {
        name
        book_cover
        status
        finish_date {
          startDate(formatString: "YYYY-MM-DD")
        }
        douban_link
      }
    }
  }
`
